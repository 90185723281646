import React, { useState } from "react";
import chartImg from "../../assets/img/images/salesTreydorex.png";
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";
import {
  ConnectWallet,
  Web3Button,
  useContract,
  useContractRead,
  useAddress,
  useBalance,
} from "@thirdweb-dev/react";

const Sales = () => {
  const [amount, setAmount] = useState(1);

  // Contract details from the iframe URL
  const contractAddress = "0x05F10aBd01bcB8c89222470f8dc20c1305Ce8177";

  // Get contract instance
  const { contract } = useContract(contractAddress);

  // Get user's address
  const address = useAddress();

  // Get token name and symbol
  const { data: name } = useContractRead(contract, "name");
  const { data: symbol } = useContractRead(contract, "symbol");

  // Get user's token balance
  const { data: tokenBalance } = useBalance(contractAddress);

  // Get user's ETH balance
  const { data: ethBalance } = useBalance();

  const chart_info_list = [
    "Marketing: 15%",
    "Engineering Development: 58%",
    "Operations & Administration: 10%",
    "Partner Engagement: 5%",
    "Legal & Regulation:10%",
    "Misc: 2%",
  ];

  return (
    <section id="sales" className="chart-area chart-bg ">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
              {/* Replace iframe with direct thirdweb implementation */}
              <div
                className="thirdweb-container"
                style={{
                  backgroundColor: "#1a1a1a",
                  borderRadius: "12px",
                  padding: "24px",
                  color: "white",
                  maxWidth: "600px",
                  height: "600px",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div style={{ marginBottom: "24px", textAlign: "center" }}>
                  <h3 style={{ fontSize: "24px", marginBottom: "8px" }}>
                    {name || "TRDX Token"} ({symbol || "TRDX"})
                  </h3>
                  <p style={{ color: "#a0a0a0", fontSize: "14px" }}>
                    Ethereum Mainnet
                  </p>
                </div>

                {/* Wallet Connection */}
                <div style={{ marginBottom: "24px" }}>
                  <ConnectWallet
                    theme="dark"
                    btnTitle="Connect Wallet"
                    style={{ width: "100%" }}
                  />
                </div>

                {address && (
                  <>
                    {/* Balances */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "24px",
                        background: "#2a2a2a",
                        padding: "16px",
                        borderRadius: "8px",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            color: "#a0a0a0",
                            fontSize: "14px",
                            marginBottom: "4px",
                          }}
                        >
                          Your Balance
                        </p>
                        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                          {tokenBalance
                            ? `${parseFloat(tokenBalance.displayValue).toFixed(
                                4
                              )} ${tokenBalance.symbol}`
                            : "Loading..."}
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            color: "#a0a0a0",
                            fontSize: "14px",
                            marginBottom: "4px",
                          }}
                        >
                          ETH Balance
                        </p>
                        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                          {ethBalance
                            ? `${parseFloat(ethBalance.displayValue).toFixed(
                                4
                              )} ETH`
                            : "Loading..."}
                        </p>
                      </div>
                    </div>

                    {/* Buy Token Form */}
                    <div style={{ marginBottom: "24px" }}>
                      <p style={{ marginBottom: "8px", fontSize: "16px" }}>
                        Buy Tokens
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "16px",
                          gap: "12px",
                        }}
                      >
                        <input
                          type="number"
                          min="1"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          style={{
                            background: "#2a2a2a",
                            border: "1px solid #3a3a3a",
                            borderRadius: "8px",
                            padding: "12px",
                            color: "white",
                            width: "100%",
                          }}
                        />
                        <span style={{ fontSize: "16px" }}>
                          {symbol || "TRDX"}
                        </span>
                      </div>

                      <Web3Button
                        contractAddress={contractAddress}
                        action={async (contract) => {
                          try {
                            // Calculate the cost based on token price (0.50 USDT per token)
                            const tokenPrice = 0.5; // USDT per token
                            const totalCost = amount * tokenPrice;

                            // For ERC20 tokens, you would typically call a purchase function
                            // This is a simplified example - adjust based on your actual contract
                            const tx = await contract.call(
                              "purchase", // Replace with your actual function name
                              [amount],
                              { value: totalCost } // If paying in ETH
                            );

                            alert(`Successfully purchased ${amount} tokens!`);
                            console.log("Transaction:", tx);
                          } catch (error) {
                            console.error("Error purchasing tokens:", error);
                            alert(
                              "Failed to purchase tokens. See console for details."
                            );
                          }
                        }}
                        theme="dark"
                        style={{ width: "100%" }}
                      >
                        Buy Tokens
                      </Web3Button>
                    </div>
                  </>
                )}

                {/* Credit Card Payment Option */}
                <div style={{ marginTop: "24px", textAlign: "center" }}>
                  <p
                    style={{
                      color: "#a0a0a0",
                      fontSize: "14px",
                      marginBottom: "12px",
                    }}
                  >
                    Don't have crypto?
                  </p>
                  <button
                    style={{
                      background: "#3498db",
                      color: "white",
                      border: "none",
                      borderRadius: "8px",
                      padding: "12px 24px",
                      fontSize: "16px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onClick={() =>
                      window.open("https://buy.moonpay.com", "_blank")
                    }
                  >
                    Buy with Credit Card
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-10">
              <div
                className="chart-content wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <SalesTabButton
                    title="Presale Live"
                    className="active"
                    id="funding-tab"
                    target="#funding"
                    ariaControls="funding"
                    ariaSelected={true}
                  />

                  <SalesTabButton
                    title="Token Allocation"
                    className=""
                    id="token-tab"
                    target="#token"
                    ariaControls="token"
                    ariaSelected={false}
                  />
                </ul>

                <div className="tab-content" id="myTabContent">
                  <SalesTabContent
                    className={"show active"}
                    id="funding"
                    ariaLabel="funding-tab"
                    title="80 TRDX = $40 USDT"
                    description=" Presale is live, game is 80 TRDX tokens.  Reserve your place today!"
                    link="/"
                  />

                  <SalesTabContent
                    className={""}
                    id="token"
                    ariaLabel="token-tab"
                    title="1 TRDX = 0.50 USDT"
                    description=" The World's 1st Entertainment Platform That Offers Rewards.  More To Be Announced soon."
                    link="/"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sales;
