import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import image from "../../assets/images/items/3.gif";
import image1 from "../../assets/images/avatar/1.jpg";
import image3 from "../../assets/images/items/2.gif";
import image4 from "../../assets/images/items/1.jpg";
import image5 from "../../assets/images/items/2.jpg";
import image6 from "../../assets/images/items/1.gif";
import { data } from "../../data/data";
import { IoMdClose, BsExclamationOctagon } from "../../assets/icons/vander";
import LayoutOne from "layouts/LayoutOne";
import {
  MediaRenderer,
  Web3Button,
  useActiveClaimConditionForWallet,
  useAddress,
  useContract,
  useContractMetadata,
  useTotalCirculatingSupply,
  useTotalCount,
  ConnectWallet,
} from "@thirdweb-dev/react";
import {
  CONTRACT_ADDRESS,
  CONTRACT_ADDRESS_COMIC,
} from "../../const/addresses";
import { ethers } from "ethers";
import Button from "components/Sales/StandaloneOnrampButton";

export default function ItemDetail() {
  const params = useParams();
  const id = params.id;
  const creater = data.find((creatorr) => creatorr.id === parseInt(id));

  const [activeIndex, setIndex] = useState(0);
  const [placeBid, setPlaceBid] = useState(false);
  const [buyNow, setBuyNow] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [activeContract, setActiveContract] = useState(CONTRACT_ADDRESS);
  const [showComicMint, setShowComicMint] = useState(false);

  useEffect(() => {
    document.documentElement.classList.add("dark");
  }, []);

  // Contract integration
  const address = useAddress();

  const { contract } = useContract(activeContract);

  const { data: contractMetadata, isLoading: isContractMetadataLoading } =
    useContractMetadata(contract);

  const { data: activeClaimPhase, isLoading: isActiveClaimPhaseLoading } =
    useActiveClaimConditionForWallet(contract, address);

  const { data: totalSupply, isLoading: isTotalSupplyLoading } =
    useTotalCount(contract);

  const { data: totalClaimed, isLoading: isTotalClaimedLoading } =
    useTotalCirculatingSupply(contract);

  const maxClaimable = parseInt(activeClaimPhase?.maxClaimablePerWallet || "0");

  const incrementQuantity = () => {
    if (maxClaimable > 0 && quantity < maxClaimable) {
      setQuantity(quantity + 1);
    } else if (maxClaimable === 0) {
      setQuantity(quantity + 1);
    }
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const toggleContract = () => {
    setActiveContract(
      activeContract === CONTRACT_ADDRESS
        ? CONTRACT_ADDRESS_COMIC
        : CONTRACT_ADDRESS
    );
    setShowComicMint(!showComicMint);
  };

  return (
    <>
      {/* <Navbar /> */}
      <LayoutOne>
        <section className="relative pt-28 md:pb-24 pb-16">
          <div className="container">
            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
              <div className="lg:col-span-5">
                <img
                  src={creater?.image ? creater?.image : image}
                  className="rounded-md shadow dark:shadow-gray-700"
                  alt=""
                />

                <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                  <div>
                    <span className="font-medium text-slate-400 block mb-1">
                      Contract Address
                    </span>
                    <Link
                      to="#"
                      className="font-medium text-violet-600 underline block"
                    >
                      {activeContract}
                    </Link>
                  </div>

                  <div className="mt-4">
                    <span className="font-medium text-slate-400 block mb-1">
                      Token ID
                    </span>
                    <span className="font-medium block">
                      {totalClaimed ? totalClaimed.toString() : "#"}
                    </span>
                  </div>

                  <div className="mt-4">
                    <span className="font-medium text-slate-400 block mb-1">
                      Blockchain
                    </span>
                    <span className="font-medium block">ETH</span>
                  </div>

                  <div className="mt-4">
                    <span className="font-medium text-slate-400 block mb-1">
                      Deposit & Withdraw
                    </span>
                    {/* <span className="font-medium block">Unsupported</span> */}
                  </div>
                </div>
              </div>

              <div className="lg:col-span-7 lg:ms-8">
                <h5 className="md:text-2xl text-xl font-semibold">
                  {contractMetadata?.name ||
                    creater?.title ||
                    "Koraxos Collection"}
                </h5>

                <span className="font-medium text-slate-400 block mt-2">
                  From this collection:{" "}
                  <Link to="/creator-profile" className="text-violet-600">
                    {creater?.subtext ? creater?.subtext : "@Treydora"}
                  </Link>
                </span>

                {/* Toggle between contracts */}
                <div className="flex justify-end mb-4 mt-4">
                  <button
                    onClick={toggleContract}
                    className="px-4 py-2 bg-violet-600 hover:bg-violet-700 text-white rounded-md"
                  >
                    {showComicMint ? "Show NFT Mint" : "Show Comic Mint"}
                  </button>
                </div>

                {/* Thirdweb NFT Minting UI */}
                <div className="bg-gray-900 rounded-lg p-6 mt-2 text-white">
                  {/* Collection Info */}
                  <div className="mb-6">
                    {!isContractMetadataLoading && contractMetadata && (
                      <div className="flex flex-col">
                        <div className="mb-4">
                          <p className="text-gray-400 text-sm">
                            {contractMetadata.description ||
                              "Mint your exclusive Treydora NFT collection."}
                          </p>
                        </div>

                        {/* Minted Count */}
                        <div className="mb-6">
                          <h3 className="text-xl font-bold mb-2">
                            {!isTotalClaimedLoading && !isTotalSupplyLoading ? (
                              <>
                                {totalClaimed?.toString()} /{" "}
                                {totalSupply?.toString()} minted
                              </>
                            ) : (
                              "Loading..."
                            )}
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Quantity Selector */}
                  <div className="flex items-center justify-between border border-gray-700 rounded-lg p-2 mb-6">
                    <button
                      onClick={decrementQuantity}
                      className="w-10 h-10 flex items-center justify-center text-2xl font-bold"
                    >
                      -
                    </button>
                    <span className="text-xl font-medium">{quantity}</span>
                    <button
                      onClick={incrementQuantity}
                      className="w-10 h-10 flex items-center justify-center text-2xl font-bold"
                    >
                      +
                    </button>
                  </div>

                  {/* Price Info */}
                  {!isActiveClaimPhaseLoading && activeClaimPhase && (
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-gray-400">Price</span>
                        <span className="font-medium">
                          {ethers.utils.formatUnits(
                            activeClaimPhase?.price || "0",
                            18
                          )}{" "}
                          ETH
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-gray-400">Total</span>
                        <span className="font-medium">
                          {ethers.utils.formatUnits(
                            ethers.BigNumber.from(
                              activeClaimPhase?.price || "0"
                            ).mul(quantity),
                            18
                          )}{" "}
                          ETH
                        </span>
                      </div>
                    </div>
                  )}

                  {/* Connect Wallet / Mint Button */}
                  {address ? (
                    <Web3Button
                      contractAddress={activeContract}
                      action={(contract) => contract.erc721.claim(quantity)}
                      className="w-full py-3 rounded-lg bg-purple-600 hover:bg-purple-700 text-white font-medium"
                    >
                      Mint NFT
                    </Web3Button>
                  ) : (
                    <ConnectWallet
                      theme="dark"
                      btnTitle="Connect Wallet"
                      className="w-full py-3 rounded-lg bg-purple-600 hover:bg-purple-700 text-white font-medium"
                    />
                  )}

                  {/* Fund Wallet Section */}
                  <div className="mt-8">
                    <h3 className="text-center text-lg font-medium mb-4">
                      FUND WALLET (VIA CREDIT CARD)
                    </h3>
                    <Button />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`fixed z-50 overflow-hidden inset-0 m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${
              placeBid ? "" : "hidden"
            }`}
          >
            <div className="relative w-full h-auto max-w-md p-4">
              <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                  <h5 className="text-xl font-semibold">Place a Bid</h5>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <IoMdClose onClick={() => setPlaceBid(!placeBid)} />
                  </button>
                </div>
                <div className="p-6">
                  <form className="text-start">
                    <div className="grid grid-cols-1">
                      <div className="mb-4">
                        <label className="font-semibold">Your Bid Price:</label>
                        <input
                          name="etherium"
                          id="number"
                          type="number"
                          className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3"
                          placeholder="00.00 ETH"
                        />
                      </div>

                      <div className="mb-4">
                        <label className="font-semibold">Enter Your QTY:</label>
                        <input
                          name="quantity"
                          id="number2"
                          type="number"
                          className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3"
                          placeholder="0"
                        />
                        <span className="text-slate-400 text-sm">
                          <span className="text-slate-900 dark:text-white mt-1">
                            Note:
                          </span>{" "}
                          Max. Qty 5
                        </span>
                      </div>
                    </div>
                  </form>

                  <div className="pt-4 border-t dark:border-t-gray-800">
                    <div className="flex justify-between">
                      <p className="font-semibold text-sm">
                        {" "}
                        You must bid at least:
                      </p>
                      <p className="text-sm text-violet-600 font-semibold">
                        {" "}
                        1.22 ETH{" "}
                      </p>
                    </div>
                    <div className="flex justify-between mt-1">
                      <p className="font-semibold text-sm"> Service free:</p>
                      <p className="text-sm text-violet-600 font-semibold">
                        {" "}
                        0.05 ETH{" "}
                      </p>
                    </div>
                    <div className="flex justify-between mt-1">
                      <p className="font-semibold text-sm">
                        {" "}
                        Total bid amount:
                      </p>
                      <p className="text-sm text-violet-600 font-semibold">
                        {" "}
                        1.27 ETH{" "}
                      </p>
                    </div>
                  </div>

                  <div className="mt-4">
                    <Link
                      to="#"
                      data-modal-toggle="NftBid"
                      className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white w-full"
                    >
                      <i className="mdi mdi-gavel"></i> Place a Bid
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`fixed z-50 overflow-hidden inset-0 m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${
              buyNow ? "" : "hidden"
            }`}
          >
            <div className="relative w-full h-auto max-w-md p-4">
              <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                  <h5 className="text-xl font-semibold">Checkout</h5>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <IoMdClose onClick={() => setBuyNow(!buyNow)} />
                  </button>
                </div>
                <div className="p-6">
                  <form className="text-start">
                    <div className="grid grid-cols-1">
                      <div className="mb-4">
                        <label className="font-semibold">Your Price:</label>
                        <input
                          name="etherium"
                          id="number3"
                          type="number"
                          className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3"
                          placeholder="00.00 ETH"
                        />
                      </div>
                    </div>
                  </form>

                  <div className="pt-4 border-t dark:border-t-gray-800">
                    <div className="flex justify-between">
                      <p className="font-semibold text-sm">
                        {" "}
                        You must bid at least:
                      </p>
                      <p className="text-sm text-violet-600 font-semibold">
                        {" "}
                        1.22 ETH{" "}
                      </p>
                    </div>
                    <div className="flex justify-between mt-1">
                      <p className="font-semibold text-sm"> Service free:</p>
                      <p className="text-sm text-violet-600 font-semibold">
                        {" "}
                        0.05 ETH{" "}
                      </p>
                    </div>
                    <div className="flex justify-between mt-1">
                      <p className="font-semibold text-sm">
                        {" "}
                        Total bid amount:
                      </p>
                      <p className="text-sm text-violet-600 font-semibold">
                        {" "}
                        1.27 ETH{" "}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center p-4 bg-red-600/10 text-red-600 mt-4 rounded-lg">
                    <BsExclamationOctagon className="text-3xl" />

                    <div className="ms-2">
                      <span className="block font-semibold">
                        This creator is not verified
                      </span>
                      <span className="block">
                        Purchase this item at your own risk
                      </span>
                    </div>
                  </div>

                  <div className="mt-4">
                    <Link
                      to="#"
                      className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white w-full"
                    >
                      <i className="mdi mdi-lightning-bolt"></i> Buy Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer />
            <Switcher /> */}
      </LayoutOne>
    </>
  );
}
