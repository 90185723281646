import LayoutOne from "layouts/LayoutOne";
import React, { useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { useSearchParams } from "react-router-dom";
import {
  FaExpand,
  FaCompress,
  FaSearch,
  FaSearchMinus,
  FaSearchPlus,
  FaVolumeUp,
  FaDownload,
} from "react-icons/fa";
import { BsGrid } from "react-icons/bs";
import {
  MdFirstPage,
  MdLastPage,
  MdNavigateBefore,
  MdNavigateNext,
} from "react-icons/md";
import comicPDF from "../../assets/pdf/comic3.pdf";
import { PDFDocument } from "pdf-lib";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Pages = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      <p>{props.children}</p>
    </div>
  );
});

function ComicBook() {
  const [numPages, setNumPages] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoom, setZoom] = useState(100);
  const [isDownloading, setIsDownloading] = useState(false);
  const containerRef = useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const comicBookRef = useRef();
  const [params] = useSearchParams();

  const imageCover = params?.get("img")
    ? decodeURIComponent(params?.get("img"))
    : "/demo.png";

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleZoom = (direction) => {
    setZoom((prev) => {
      const newZoom = direction === "in" ? prev + 10 : prev - 10;
      return Math.min(Math.max(newZoom, 50), 200);
    });
  };

  const downloadPDFWithCover = async () => {
    try {
      setIsDownloading(true);

      // Fetch the original PDF
      const existingPdfBytes = await fetch("/comic3.pdf").then((res) =>
        res.arrayBuffer()
      );

      // Fetch the cover image
      const coverImageResponse = await fetch(imageCover);
      const coverImageBlob = await coverImageResponse.blob();

      // Convert the image to base64
      const coverImageBase64 = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(coverImageBlob);
      });

      // Create a new PDF document
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Create a new page for the cover (as the first page)
      const coverPage = pdfDoc.insertPage(0);

      // Embed the image
      let image;
      if (
        coverImageBase64.includes("image/jpeg") ||
        coverImageBase64.includes("image/jpg")
      ) {
        const jpgImage = await pdfDoc.embedJpg(coverImageBase64);
        image = jpgImage;
      } else if (coverImageBase64.includes("image/png")) {
        const pngImage = await pdfDoc.embedPng(coverImageBase64);
        image = pngImage;
      } else {
        throw new Error(
          "Unsupported image format. Only JPEG and PNG are supported."
        );
      }

      // Get dimensions
      const { width, height } = coverPage.getSize();

      // Calculate dimensions to maintain aspect ratio
      const imgWidth = width;
      const imgHeight = (image.height / image.width) * imgWidth;

      // Draw the image on the cover page
      coverPage.drawImage(image, {
        x: 0,
        y: 0,
        width: imgWidth,
        height: imgHeight,
      });

      // Save the PDF
      const pdfBytes = await pdfDoc.save();

      // Create a blob from the PDF bytes
      const blob = new Blob([pdfBytes], { type: "application/pdf" });

      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "comic_with_cover.pdf";

      // Trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
    } catch (error) {
      console.error("Error creating PDF:", error);
      setIsDownloading(false);
      alert("Failed to download PDF. Please try again.");
    }
  };

  return (
    <LayoutOne>
      <div
        ref={containerRef}
        className="min-h-screen flex flex-col items-center justify-center relative"
        style={{
          transform: `scale(${zoom / 100})`,
          transition: "transform 0.3s ease",
        }}
      >
        <div
          style={{
            backgroundColor: "red !important",
          }}
          className="flex-1 flex items-center justify-center w-full mt-10"
        >
          <HTMLFlipBook
            width={500}
            height={850}
            showPageCorners
            maxShadowOpacity={0.5}
            mobileScrollSupport={true}
            size="stretch"
            showCover={true}
            usePortrait
            maxWidth={500}
            maxHeight={850}
            ref={comicBookRef}
            onFlip={(e) => {
              setActivePageNumber(
                comicBookRef?.current?.pageFlip()?.getCurrentPageIndex() + 1
              );
            }}
            startPage={0}
            style={{
              marginRight: activePageNumber === 1 ? "500px" : "0px",
              transition: "all 150ms ease-in-out",
            }}
          >
            <div className="w-[500px] ">
              <img
                src={`${imageCover ?? "/demo.png"}`}
                alt="Demo Cover"
                className="w-full h-full "
              />
            </div>

            {[...Array(numPages)?.keys()].map((n) => (
              <Pages key={n} number={`${n + 1}`}>
                <Document
                  file={"/comic3.pdf"}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(err) => console.log(err, "Error loading pdf")}
                  onError={(err) => console.log(err)}
                >
                  <Page
                    pageNumber={n + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    width={500}
                    height={850}
                    className="border-3 border-black mx-0 my-0"
                  />
                </Document>
              </Pages>
            ))}
          </HTMLFlipBook>
        </div>

        {/* Toolbar */}
        <div className="w-full bg-gray-900 text-white py-3 px-4 flex items-center justify-between mt-10 fixed bottom-0">
          <div className="flex items-center gap-4">
            <button className="hover:text-gray-300"></button>
            <button className="hover:text-gray-300"></button>
            <button
              className="hover:text-gray-300 flex items-center gap-2 bg-blue-600 px-3 py-1 rounded"
              onClick={downloadPDFWithCover}
              disabled={isDownloading}
            >
              <FaDownload size={16} />
              {isDownloading ? "Processing..." : "Download PDF"}
            </button>
          </div>

          <div className="flex items-center gap-6">
            <button
              className="hover:text-gray-300"
              onClick={() => comicBookRef?.current?.pageFlip().flip(0)}
            >
              <MdFirstPage size={24} />
            </button>
            <button
              className="hover:text-gray-300"
              onClick={() => comicBookRef?.current?.pageFlip().flipPrev()}
            >
              <MdNavigateBefore size={24} />
            </button>

            <div className="text-sm">
              <span>{activePageNumber}</span>
              <span className="mx-2">/</span>
              <span>{numPages ? numPages + 1 : "-"}</span>
            </div>

            <button
              className="hover:text-gray-300"
              onClick={() => comicBookRef?.current?.pageFlip().flipNext()}
            >
              <MdNavigateNext size={24} />
            </button>
            <button
              className="hover:text-gray-300"
              onClick={() => comicBookRef?.current?.pageFlip().flip(numPages)}
            >
              <MdLastPage size={24} />
            </button>
          </div>

          <div className="flex items-center gap-4">
            <button
              className="hover:text-gray-300"
              onClick={() => handleZoom("out")}
            >
              <FaSearchMinus size={20} />
            </button>
            <button
              className="hover:text-gray-300"
              onClick={() => handleZoom("in")}
            >
              <FaSearchPlus size={20} />
            </button>
            <button className="hover:text-gray-300" onClick={toggleFullscreen}>
              {isFullscreen ? <FaCompress size={20} /> : <FaExpand size={20} />}
            </button>
          </div>
        </div>
      </div>
    </LayoutOne>
  );
}

export default ComicBook;
